import type { PricingItem } from "~/queries/pricing.server";
import type {
	DataByProviders,
	PricingProvider,
	PricingDefaultFilters,
	ProductSignupParams,
	PlanData,
	GroupedPlansData,
} from "~/types/product";
import orderBy from "lodash/orderBy";
import groupBy from "lodash/groupBy";

export function getPricingRegionForProvider(
	data: DataByProviders,
	provider: PricingProvider,
	pricingDefaultFilters: PricingDefaultFilters
): string {
	const regions = orderBy(Object.keys(data[provider]));
	const defaultRegion = pricingDefaultFilters.defaultRegionPerCloud[provider];
	const region = regions.includes(defaultRegion) ? defaultRegion : regions[0];
	return region;
}

export function getPricingDefaultFilters({
	cloud = "Amazon Web Services",
}: {
	cloud?: keyof PricingDefaultFilters["defaultRegionPerCloud"];
} = {}): PricingDefaultFilters {
	const defaultRegionPerCloud: PricingDefaultFilters["defaultRegionPerCloud"] =
		{
			UpCloud: "upcloud-de-fra",
			DigitalOcean: "do-nyc",
			"Google Cloud": "google-europe-west1",
			"Microsoft Azure": "azure-westeurope",
			"Amazon Web Services": "aws-eu-west-1",
		};

	const region: string = defaultRegionPerCloud[cloud];

	const defaultFilters: PricingDefaultFilters = {
		defaultOptions: {
			cloud: cloud,
			region: region,
			addon: "",
		},
		defaultRegionPerCloud: defaultRegionPerCloud,
	};

	return defaultFilters;
}

// Mangle pricing data into a more usable form for the UI
export function getPricingData(
	plans: PricingItem["plans"],
	defaults: PricingDefaultFilters
): {
	data: DataByProviders;
	providers: string[];
	addons: string[];
	initialProvider: string;
	initialRegion: string;
	initialAddon: string;
} {
	const data: DataByProviders = plans.reduce((acc: DataByProviders, plan) => {
		plan.regions.forEach((region) => {
			if (!region.provider) {
				return;
			}

			if (!acc[region.provider]) {
				acc[region.provider] = {};
			}

			if (!acc[region.provider][region.id]) {
				acc[region.provider][region.id] = {
					id: region.id,
					shortId: region.shortId,
					location: region.location,
					plans: [],
					addons: [...region.addons],
				};
			}

			acc[region.provider][region.id].plans.push({
				name: plan.name,
				nodeCount: plan.nodeCount,
				shardCount: plan.shardCount,
				diskSpaceGb: region.diskSpaceGb,
				diskSpaceMb: region.diskSpaceMb,
				diskSpaceCapMB: region.diskSpaceCapMB,
				diskSpaceGbPriceUsd: region.diskSpaceGbPriceUsd,
				diskSpaceStepMB: region.diskSpaceStepMB,
				cpuCount: region.cpuCount,
				memoryGb: region.memoryGb,
				pricePerHourUsd: region.pricePerHourUsd,
				pricePerMonthUsd: region.pricePerMonthUsd,
			});
		});
		return acc;
	}, {});

	const providers = orderBy(Object.keys(data));
	const initialProvider =
		providers.find((p) => p === defaults.defaultOptions.cloud) || providers[0];
	const initialRegion =
		data[initialProvider][defaults.defaultOptions.region]?.id ||
		orderBy(Object.keys(data[initialProvider]))[0];
	const addons = data[initialProvider][initialRegion].addons.map(
		(addon) => addon.type
	);
	const initialAddon =
		addons.find((a) => a === defaults.defaultOptions.addon) || addons[0];

	return {
		data,
		providers,
		addons,
		initialProvider,
		initialRegion,
		initialAddon,
	};
}

export function getAddonNameForType(type: string): string {
	const typeNameMapping: Record<string, string> = {
		"aws-tgw-vpc-attachment": "AWS Transit Gateway",
		"sip-vpc-attachment": "Static IP Addresses",
		"pl-vpc-attachment": "PrivateLink",
		"kafka-tiered-storage": "Tiered Storage",
	};
	return typeNameMapping[type];
}

export function getPricingAddonForProviderAndRegion(
	data: DataByProviders,
	provider: string,
	region: string
): string | undefined {
	const { addons } = data[provider][region];
	return addons[0]?.type;
}

export function getPricingTableData({
	plans,
}: {
	plans: Array<PlanData>;
}): GroupedPlansData {
	function validPlanGroup(plan: string) {
		const allowedGroup = ["Free", "Hobbyist", "Startup", "Business", "Premium"];
		const regex = new RegExp(`^(${allowedGroup.join("|")})[-\\d\\w]*$`, "i");

		return regex.test(plan);
	}

	const grouped = groupBy(plans, (plan) => plan.name.split("-")[0]); // eg. Startup, Business, Premium

	const tableData = Object.entries(grouped).reduce(
		(acc: GroupedPlansData, [groupName, groupPlans]) => {
			if (!validPlanGroup(groupName)) return acc;

			acc[groupName] = {
				name: groupName,
				minNodeCount: Math.min(...groupPlans.map((x) => x.nodeCount)),
				maxNodeCount: Math.max(...groupPlans.map((x) => x.nodeCount)),
				minShardCount: Math.min(...groupPlans.map((x) => x.shardCount)),
				maxShardCount: Math.max(...groupPlans.map((x) => x.shardCount)),
				minCpuCount: Math.min(...groupPlans.map((x) => x.cpuCount)),
				maxCpuCount: Math.max(...groupPlans.map((x) => x.cpuCount)),
				minMemoryGb: Math.min(...groupPlans.map((x) => x.memoryGb)),
				maxMemoryGb: Math.max(...groupPlans.map((x) => x.memoryGb)),
				minDiskSpaceGb: Math.min(...groupPlans.map((x) => x.diskSpaceGb)),
				maxDiskSpaceGb: Math.max(...groupPlans.map((x) => x.diskSpaceGb)),
				minPricePerHourUsd: Math.min(
					...groupPlans.map((x) => x.pricePerHourUsd)
				),
				minPricePerMonthUsd: Math.min(
					...groupPlans.map((x) => x.pricePerMonthUsd)
				),
				plans: groupPlans,
			};
			return acc;
		},
		{}
	);

	return tableData;
}

export function getDisabledColumns(data: GroupedPlansData["group"]): {
	nodeCount: boolean;
	shardCount: boolean;
	cpuCount: boolean;
	memoryGb: boolean;
	diskSpaceGb: boolean;
} {
	const nodeCount = data.minNodeCount + data.maxNodeCount;
	const shardCount = data.minShardCount + data.maxShardCount;
	const cpuCount = data.minCpuCount + data.maxCpuCount;
	const memoryGb = data.minMemoryGb + data.maxMemoryGb;
	const diskSpaceGb = data.minDiskSpaceGb + data.maxDiskSpaceGb;

	// Disable columns that don't have any data (only zeros)
	return {
		nodeCount: isNaN(nodeCount) || nodeCount === 0,
		shardCount: isNaN(shardCount) || shardCount === 0,
		cpuCount: isNaN(cpuCount) || cpuCount === 0,
		memoryGb: isNaN(memoryGb) || memoryGb === 0,
		diskSpaceGb: isNaN(diskSpaceGb) || diskSpaceGb === 0,
	};
}

export function getSignupUrl({
	service,
	cloud,
	plan,
	webSource = "pricing-table",
}: ProductSignupParams): string {
	const signupService = encodeURIComponent(service);
	let signupUrl = `https://console.aiven.io/signup?web_source=${webSource}&service=${signupService}`;

	if (cloud) {
		signupUrl += `&cloud=${encodeURIComponent(cloud)}`;
	}

	if (plan) {
		signupUrl += `&plan_type=${encodeURIComponent(plan.toLowerCase())}`;
	}

	return signupUrl;
}

export function roundPrice(price: number) {
	// 2 decimal points
	return Math.round(price * 100) / 100;
}

export function formatCurrency({
	value,
	fractionDigits = 2,
}: {
	value: number;
	fractionDigits?: 0 | 2;
}): string {
	const standardFormatted = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		currencyDisplay: "narrowSymbol",
		minimumFractionDigits: fractionDigits,
		maximumFractionDigits: fractionDigits,
	}).format(value);

	return standardFormatted;
}

export function getMinMaxValue(min: number, max: number) {
	return min !== max ? `${min}-${max}` : `${min}`;
}

export const MbToGb = (mb: number) => Math.round(mb / 1024);

export const GbToMb = (gb: number) => Math.round(gb * 1024);
